import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {SamDefinition} from '../../shared/market';
import {SamDefinitionSizeResponse} from '../../shared/api/api';

export function useSamDefinitionSize({
  enabled,
  onSuccess,
  samDefinition,
}: {
  enabled: boolean;
  onSuccess?: (data: SamDefinitionSizeResponse) => void;
  samDefinition: SamDefinition;
}) {
  const keyplayApi = useKeyplayApi();
  return useQuery(
    ['samDefinitionSize', JSON.stringify(samDefinition)],
    () =>
      keyplayApi<SamDefinitionSizeResponse>(
        `/samDefinitionSize?sam=${JSON.stringify(samDefinition)}`,
        {
          method: 'GET',
        },
        {toastOnError: true}
      ),
    {
      enabled,
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
}

import produce from 'immer';
import {StoreApi, createStore, useStore} from 'zustand';
import {devtools} from 'zustand/middleware';
import {ScoringMode} from '../../../shared/market';
import {useEffect} from 'react';
import _ from 'lodash';
import {useMutation} from '@tanstack/react-query';
import {useMarketFromContext} from '../MarketProvider';
import {usePublishMarket} from '../../../hooks/api/markets';

interface ScoringModeState {
  hasInitialized: boolean;
  updateWithLatestServerState: (serverState?: ScoringMode) => void;

  serverState: ScoringMode | null;
  clientState: ScoringMode | null;

  setScoringMode: (mode: ScoringMode) => void;
  resetState: (resetState?: ScoringMode) => void;
  persistState: (mode: ScoringMode) => void;
  hasChanges: (comparisonState?: ScoringMode) => boolean;
}

const createScoringModeStore = () =>
  createStore<ScoringModeState>()(
    devtools<ScoringModeState>((set, get) => {
      return {
        hasInitialized: false,
        updateWithLatestServerState: (serverState = {type: 'highest_value'}) =>
          set(
            produce<ScoringModeState>((state) => {
              state.hasInitialized = true;

              if (_.isEqual(get().clientState, get().serverState)) {
                state.clientState = serverState;
              }

              state.serverState = serverState;
            })
          ),

        clientState: null,
        serverState: null,

        setScoringMode: (mode) =>
          set(
            produce<ScoringModeState>((state) => {
              if (!state.hasInitialized) {
                return;
              }
              state.clientState = mode;
            })
          ),
        resetState: (resetState) =>
          set(
            produce<ScoringModeState>((state) => {
              if (!state.hasInitialized) {
                return;
              }
              state.clientState = resetState ?? get().serverState;
            })
          ),
        persistState: (mode: ScoringMode) =>
          set(
            produce<ScoringModeState>((state) => {
              if (!state.hasInitialized) {
                return;
              }
              state.clientState = mode;
              state.serverState = mode;
            })
          ),
        hasChanges: (comparisonState) =>
          get().hasInitialized &&
          !_.isEqual(get().clientState, comparisonState ?? get().serverState),
      };
    })
  );

const scoringModeStores: Record<string, StoreApi<ScoringModeState>> = {};

export const useScoringModeState = () => {
  const {id: marketId, scoringModel} = useMarketFromContext();
  const publishMarket = usePublishMarket();

  let scoringModeStore = scoringModeStores[marketId.toString()];
  if (!scoringModeStore) {
    scoringModeStores[marketId.toString()] = createScoringModeStore();
    scoringModeStore = scoringModeStores[marketId.toString()];
  }
  const store = useStore(scoringModeStore);

  const {updateWithLatestServerState} = store;

  useEffect(() => {
    if (!scoringModel) {
      return;
    }

    updateWithLatestServerState(scoringModel.scoringMode);
  }, [scoringModel, updateWithLatestServerState]);

  const publishChanges = useMutation({
    mutationFn: async () => {
      if (!store.hasInitialized) {
        throw new Error('Cannot publish changes before initializing');
      }

      if (!store.clientState) {
        throw new Error('No state to publish');
      }

      return publishMarket.mutateAsync({
        marketId,
        scoringModel: {scoringMode: store.clientState},
      });
    },
    onSuccess: async ({scoringModel}) => {
      store.persistState(scoringModel.scoringMode);
    },
  });

  return {
    ...store,
    publishChanges,
  };
};

import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router';
import {MarketsList} from './MarketsList';
import {MarketBuilder} from './MarketBuilder/MarketBuilder';
import {useVerifiedMarketId} from '../../hooks/api/metadata';
import {MarketProvider} from './MarketProvider';
import {useMarket} from '../../hooks/api/markets';
import {ObjectId} from 'bson';
import {Center} from '@chakra-ui/react';
import {DelayedSpinner} from '../DelayedSpinner';
import {Title} from '../Title';

export const Markets = () => {
  return (
    <Routes>
      <Route path=":marketId/*" element={<Market />} />
      <Route index element={<MarketsList />} />
    </Routes>
  );
};

const Market = () => {
  const {marketId} = useParams();
  const verifiedMarketId = useVerifiedMarketId(marketId);

  if (!verifiedMarketId) {
    return <Navigate to="/markets" />;
  }

  return <_Market marketId={verifiedMarketId} />;
};

const _Market = ({marketId}: {marketId: ObjectId}) => {
  const {data} = useMarket({marketId: marketId.toString()});

  if (!data) {
    return (
      <Center h="100%">
        <DelayedSpinner />
      </Center>
    );
  }

  return (
    <MarketProvider market={data}>
      <Title title={data.label} />
      <Routes>
        <Route path="*" element={<MarketBuilder />} />
      </Routes>
    </MarketProvider>
  );
};

import React from 'react';
import {
  Box,
  Button,
  Flex,
  Spacer,
  BoxProps,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import {PageTitle} from '../PageTitle';
import {Link} from 'react-router-dom';
import {ObjectId} from 'bson';
import {pluralize} from '../../lib/helpers';
import {
  useDefaultMarketId,
  useIsFreeOrListBuilderCustomer,
  useMarkets,
} from '../../hooks/api/metadata';
import {useMarket} from '../../hooks/api/markets';
import {Market} from '../../shared/market';

const buttonLinkWrapperStyle: BoxProps = {
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
};

const buttonLinkProps: BoxProps = {
  borderWidth: 1,
  borderColor: 'kblue.400',
  borderRadius: '8px',
  color: 'kblue.400',
  fontWeight: 500,
  fontSize: 14,
  px: 6,
  py: 3,
  transitionDuration: '200ms',
  transitionProperty:
    'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;',
  _hover: {
    textDecoration: 'none',
    bg: 'kblue.50',
  },
  _groupFocusVisible: {
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6);',
  },
};

const SummaryTags = ({marketId}: {marketId: ObjectId}) => {
  const defaultMarketId = useDefaultMarketId();
  const {data} = useMarket({marketId: marketId.toString()});
  if (!data) {
    return <></>;
  }

  const {scoringModel} = data;
  const numLookalikes = scoringModel.lookalikes?.length ?? 0;
  const numSignals = scoringModel.signalsWithWeights?.length ?? 0;

  const tags = [
    pluralize(numLookalikes, 'lookalike', 'lookalikes'),
    pluralize(numSignals, 'signal', 'signals'),
  ];
  return (
    <>
      {tags.map((t) => (
        <Tag
          color="kgray.300"
          variant="outline"
          key={t}
          mr={2}
          size={'sm'}
          fontWeight={300}
          fontSize={10}
          textTransform="uppercase"
        >
          {t}
        </Tag>
      ))}
      {defaultMarketId.equals(marketId) && (
        <Tag
          key="default"
          mr={2}
          size={'sm'}
          fontWeight={300}
          fontSize={10}
          textTransform="uppercase"
        >
          Default
        </Tag>
      )}
    </>
  );
};

const MarketRow = ({market}: {market: Market}) => {
  return (
    <Box bg="white" px={8} pt={4} key={market.id.toString()}>
      <Box borderRadius={8} borderColor="kgray.200" borderWidth={1} p={8}>
        <Flex alignItems="center">
          <Box fontSize={20} fontWeight={500} mr={4}>
            {market.label}
          </Box>
          <SummaryTags marketId={market.id} />
          <Spacer />
          <Box
            as={Link}
            role="group"
            {...buttonLinkWrapperStyle}
            to={`/markets/${market.id}`}
            mr={2}
          >
            <Box {...buttonLinkProps}>Adjust Market &amp; Scoring</Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export const MarketsList = () => {
  const markets = useMarkets();
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();

  return (
    <Box>
      <Flex bg="#F8F8FB" px={8} h={20} alignItems="center">
        <PageTitle my={0}>Markets</PageTitle>
        <Spacer />
        <Tooltip
          hasArrow
          label={
            isFreeOrListBuilderCustomer
              ? 'Want additional markets? Contact us to schedule a demo.'
              : "Multiple markets is currently in beta. Contact us if you're interested in learning more."
          }
          bg="black"
          placement="bottom-end"
        >
          <Button isDisabled={true} colorScheme="kbuttonblue">
            New Market
          </Button>
        </Tooltip>
      </Flex>
      <Box color="kgray.300" mx={8} whiteSpace="nowrap" mt={8}>
        {pluralize(markets.length, 'market', 'markets')}
      </Box>
      {markets.map((market) => (
        <MarketRow market={market} key={market.id.toString()} />
      ))}
    </Box>
  );
};

import React from 'react';
import {
  Box,
  Flex,
  Link,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  FieldDefinitionTypeLabels,
  getSignalEvalFnInputs,
  SignalDefinition,
  SignalEvalFnInput,
} from '../../shared/enrichment';
import {useCustomer, useMarkets} from '../../hooks/api/metadata';
import {pluralize} from '../../lib/helpers';
import {SimpleSignal} from '../signals';
import {assertNever} from '../../shared/util';
import {ScoringSignalToLabel} from '../../shared/signals';
import {AccountFieldLabels, isAccountField} from '../../shared/fields';
import {IbmWatsonStudio, Store} from '@carbon/icons-react';
import {getSignalsInMarket} from '../../shared/market';
import {Link as ReactRouterLink} from 'react-router-dom';

export const CustomSignals = () => {
  const customer = useCustomer();
  const signalDefinitions = (customer.signalDefinitions ?? []).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Text fontWeight="500">Custom Signals</Text>
        <Text textColor="kgray.300" fontSize="sm">
          {pluralize(signalDefinitions.length, 'signal', 'signals')}
        </Text>
      </Box>
      <CustomSignalsList signalDefinitions={signalDefinitions} />
    </Flex>
  );
};

const columnHelper = createColumnHelper<SignalDefinition>();
const useColumns = () => {
  const fieldDefinitions = useCustomer().fieldDefinitions ?? [];
  const markets = useMarkets();

  const formatSignalEvalInput = (input: SignalEvalFnInput) => {
    const {type} = input;
    switch (type) {
      case 'field':
        if (isAccountField(input.field)) {
          return `${AccountFieldLabels[input.field]} (Keyplay Field)`;
        } else {
          const field = fieldDefinitions.find((f) => f.id.equals(input.field));
          return field
            ? `${field.label} (${FieldDefinitionTypeLabels[field.type]})`
            : 'Unknown Field';
        }

      case 'signal':
        return `${ScoringSignalToLabel[input.signal]} (Keyplay Signal)`;

      default:
        assertNever(type);
    }
  };

  return [
    columnHelper.accessor('id', {
      header: 'Signal Name',
      size: 0,
      cell: (info) => {
        return (
          <SimpleSignal
            fontSize="sm"
            signal={info.getValue()}
            w="min-content"
          />
        );
      },
    }),
    columnHelper.accessor('evalFn', {
      header: 'Input',
      size: 400,
      cell: (info) => {
        let signalInput;
        let viewMoreInputs;

        const evalFnInputs = getSignalEvalFnInputs(info.getValue());
        if (evalFnInputs.length === 1) {
          signalInput = formatSignalEvalInput(evalFnInputs[0]);
        } else {
          signalInput = `${evalFnInputs.length} inputs`;
          viewMoreInputs = (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box cursor="help">
                  <IbmWatsonStudio />
                </Box>
              </PopoverTrigger>
              <PopoverContent p="2">
                <PopoverArrow />
                <PopoverBody>
                  <List spacing="2">
                    {evalFnInputs.map((input, index) => (
                      <ListItem
                        key={index}
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {formatSignalEvalInput(input)}
                      </ListItem>
                    ))}
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        }

        return (
          <Flex gap="2" alignItems="center" textColor="kgray.300">
            {signalInput}
            {viewMoreInputs}
          </Flex>
        );
      },
    }),
    columnHelper.accessor((data) => data.id, {
      id: 'usedIn',
      header: 'Used In',
      cell: (info) => {
        const marketsUsedIn = markets.filter((market) =>
          getSignalsInMarket(market).includes(info.getValue())
        );
        return (
          <Flex
            gap="2"
            alignItems="center"
            justifyContent="end"
            textColor="kgray.300"
            w="80%"
          >
            {pluralize(marketsUsedIn.length, 'market', 'markets')}
            <Popover trigger="hover" size="sm">
              <PopoverTrigger>
                <Box cursor="help">
                  <Store />
                </Box>
              </PopoverTrigger>
              <PopoverContent p="2">
                <PopoverArrow />
                <PopoverBody>
                  <List spacing="2">
                    {marketsUsedIn.map(({id, label}) => (
                      <ListItem
                        key={id.toString()}
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        <Link
                          as={ReactRouterLink}
                          to={`/markets/${id}`}
                          textColor="kblue.200"
                          _hover={{textDecoration: 'underline'}}
                        >
                          {label}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('timestamp', {
      header: 'Created',
      cell: (info) => {
        return (
          <Box textColor="kgray.300">
            {info.getValue().toLocaleDateString()}
          </Box>
        );
      },
    }),
  ];
};

const CustomSignalsList = ({
  signalDefinitions,
}: {
  signalDefinitions: SignalDefinition[];
}) => {
  const columns = useColumns();
  const table = useReactTable({
    columns,
    data: signalDefinitions,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  if (!signalDefinitions.length) {
    return (
      <Flex direction="column" align="center" p={8}>
        <Text textColor="kgray.300">No custom signals defined.</Text>
      </Flex>
    );
  }

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  _groupHover={{bg: 'kgray.100'}}
                  w={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

import React, {createContext, useContext} from 'react';
import {GetMarketResponse} from '../../shared/api/responses';

const MarketContext = createContext<{
  market: GetMarketResponse;
} | null>(null);

export const MarketProvider = ({
  children,
  market,
}: React.PropsWithChildren<{market: GetMarketResponse}>) => {
  const context = {market};

  return (
    <MarketContext.Provider value={context}>{children}</MarketContext.Provider>
  );
};

export const useMarketFromContext = () => {
  const provider = useContext(MarketContext);
  if (!provider) {
    throw new Error('MarketContext not found');
  }

  return provider.market;
};

import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from './api';
import {GetMarket, PostMarket} from '../../shared/api/definitions';
import {ScoringModelTestKeys} from './scoringModel';
import {MetadataQueryKey} from '../../context/MetadataContext';
import {ScoringInfoQueryKey} from './scoringInfo';
import {GetMarketResponse} from '../../shared/api/responses';

export const MarketQueryKeys = {
  all: ['markets'],
  market: (marketId: string) => [...MarketQueryKeys.all, marketId],
} as const;

export const usePublishMarket = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/market', PostMarket, {
    onSuccess: async (
      updatedMarket,
      {marketId, scoringModel, triggerRefresh}
    ) => {
      const marketIdStr = marketId.toString();

      // Update the cache with the new market data
      queryClient.setQueryData<GetMarketResponse>(
        MarketQueryKeys.market(marketIdStr),
        () => updatedMarket
      );

      const keysToInvalidate = [
        // Refetch the list of markets
        [MetadataQueryKey],
        ...(scoringModel ? [ScoringModelTestKeys.market(marketIdStr)] : []),
        ...(triggerRefresh ? [[ScoringInfoQueryKey]] : []),
      ];
      await Promise.all(
        keysToInvalidate.map((queryKeys) =>
          queryClient.invalidateQueries(queryKeys)
        )
      );
    },
  });
};

export const useMarket = createApiQuery('/market', GetMarket, ({marketId}) =>
  MarketQueryKeys.market(marketId)
);

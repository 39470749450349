import React from 'react';
import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import {Navigate, useParams} from 'react-router';
import {Link as ReactRouterLink} from 'react-router-dom';
import {AllIntegrations} from './AllIntegrations';
import {useCrmFields, useIntegrations} from '../../hooks/api/integrations';
import {
  IntegrationStatus,
  CoreStandardEnrichmentFields,
  OptionalStandardEnrichmentFields,
  CoreAdditionalEnrichmentFields,
  OptionalAdditionalEnrichmentFields,
} from '../../shared/integrations';
import {
  CustomerIntegration,
  isCustomerIntegration,
} from '../../shared/customer';
import {
  isAdditionalEnrichmentField,
  isStandardEnrichmentField,
} from '../../shared/integrations';
import _ from 'lodash';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import {StandardEnrichmentFieldMapping} from './StandardEnrichmentFieldMapping';
import {AdditionalEnrichmentFieldMapping} from './AdditionalEnrichmentFieldMapping';
import {Settings} from './SyncSettings';
import {StatusHeader} from './StatusHeader';
import {useMarkets} from '../../hooks/api/metadata';
import {Market} from '../../shared/market';
import {Title} from '../Title';

export const IntegrationDetails = () => {
  const integrations = useIntegrations();
  const markets = useMarkets();
  const {integration} = useParams();

  if (!isCustomerIntegration(integration)) {
    return <Navigate to={'/settings/integrations'} />;
  }

  if (integrations.isLoading) {
    return <Spinner />;
  }

  if (integrations.isError || !integrations.data[integration]) {
    return <Navigate to={'/settings/integrations'} />;
  }

  return (
    <Title title={AllIntegrations[integration].label}>
      <IntegrationDetails_ integrationName={integration} markets={markets} />
    </Title>
  );
};

const IntegrationDetails_ = ({
  integrationName,
  markets,
}: {
  integrationName: CustomerIntegration;
  markets: Market[];
}) => {
  const {serverState, standardFields, additionalFields} =
    useIntegrationDetailsState(integrationName);
  const integrationInfo = AllIntegrations[integrationName];

  // Queries
  const crmFieldsResult = useCrmFields(integrationName);
  const optionalFields = serverState?.config?.optionalFields ?? [];
  const availableStandardEnrichmentFields = CoreStandardEnrichmentFields.concat(
    _.intersection(
      optionalFields.filter(isStandardEnrichmentField),
      OptionalStandardEnrichmentFields
    )
  );

  const availableAdditionalEnrichmentFields =
    CoreAdditionalEnrichmentFields.concat(
      _.intersection(
        optionalFields.filter(isAdditionalEnrichmentField),
        OptionalAdditionalEnrichmentFields
      )
    );

  return (
    <Flex direction="column">
      <Flex direction="column" py={4} px={8} bgColor={'kgray.100'}>
        {/* Navigation */}
        <Breadcrumb separator={'>'}>
          <BreadcrumbItem>
            <BreadcrumbLink as={ReactRouterLink} to={'/settings/integrations'}>
              Integrations
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{integrationInfo.label}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <StatusHeader integrationName={integrationName} />
      </Flex>

      {/* Body */}
      <Flex direction="column" my={8} gap={4}>
        <Settings integrationName={integrationName} />
        <StandardEnrichmentFieldMapping
          integrationName={integrationName}
          integrationInfo={integrationInfo}
          availableEnrichmentFields={availableStandardEnrichmentFields}
          enrichmentFields={standardFields}
        />
        <AdditionalEnrichmentFieldMapping
          integrationName={integrationName}
          integrationInfo={integrationInfo}
          availableEnrichmentFields={availableAdditionalEnrichmentFields}
          enrichmentFields={additionalFields}
          crmFieldsResult={crmFieldsResult}
          markets={markets}
        />
      </Flex>
    </Flex>
  );
};

const StatusText = {
  active: 'Connected',
  disconnected: 'Disconnected',
  refresh_token_expired: 'Auth Error',
} satisfies Record<IntegrationStatus, string>;

const StatusStyle = {
  active: {
    bgColor: 'kgreen.100',
    color: 'kgreen.500',
  },
  disconnected: {
    bgColor: 'kyellow.100',
    color: 'kgray.400',
  },
  refresh_token_expired: {
    bgColor: 'kred.100',
    color: 'kred.500',
  },
} satisfies Record<IntegrationStatus, Partial<BoxProps>>;

type StatusBadgeProps = BoxProps & {
  status?: IntegrationStatus;
};

export const StatusBadge = ({status, ...props}: StatusBadgeProps) => {
  if (!status) {
    return <Spinner size="sm" />;
  }

  return (
    <Box
      borderRadius={'md'}
      fontSize={'xs'}
      p={1}
      textTransform={'uppercase'}
      {...StatusStyle[status]}
      {...props}
    >
      {StatusText[status]}
    </Box>
  );
};

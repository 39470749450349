import React, {useEffect, useState} from 'react';
import {Spinner, SpinnerProps} from '@chakra-ui/react';

export const DelayedSpinner = ({
  delayMs = 500,
  ...props
}: SpinnerProps & {delayMs?: number}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowSpinner(true), delayMs);
    return () => clearTimeout(timeoutId);
  }, [delayMs]);

  return showSpinner ? <Spinner {...props} /> : null;
};

import {OptionalAccountField} from './fields';
import {Market} from './market';
import {
  HubSpotIntegration,
  SalesforceIntegration,
  SyncType,
} from './integrations';
import {Rollup} from './scoredAccounts';
import {OptionalScoringSignal} from './signals';
import {ObjectId} from 'bson';
import {WithId, WithRequired, keys} from '../shared/util';
import _ from 'lodash';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {FieldDefinition, SignalDefinition} from './enrichment';
import {MetadataResponse} from './api/api';
import {ListBuilderActiveAccountsLimit} from './listBuilder';

export interface ApiKey {
  _id: ObjectId;
  key: string;
  generated: Date;
  lastUsed?: Date;
}

const CustomerOptionsSchema = z.object({
  // For demo accounts we'd prefer not to keep mutations.
  skipMutations: z.boolean().optional(),

  // Don't allow an account to be wiped by a script unless we
  // explicitly mark it as safe. Generally should only be set
  // manually right before a wipe, or on internal accounts that
  // we expect to be wiped often.
  safeToWipe: z.boolean().optional(),

  // Whether we add accounts enriched through the API.
  addOnEnrich: z.boolean().optional(),

  // For internal accounts we export additional columns
  includeInternalExportColumns: z.boolean().optional(),

  // For some customers we only want to pull/sync new CRM records (those created after
  // their integration auth date)
  onlyPullAndSyncNewCrmRecords: z.boolean().optional(),
});
export type CustomerOptions = z.infer<typeof CustomerOptionsSchema>;
export const isCustomerOptions = zodTypeguard(CustomerOptionsSchema);

const SettableCustomerOptionsSchema = CustomerOptionsSchema.pick({
  addOnEnrich: true,
}).keyof();
export const isSettableCustomerOption = zodTypeguard(
  SettableCustomerOptionsSchema
);

// Toggles are meant to be temporary flags that can be used to enable
// certain functionality, regardless of plan.
// When you remove a Toggle, remember to run the following script to clean
// up database entries
// npm run remove-invalid-customer-settings
export const Toggles = ['legacySalesforceFields', 'modelTester'] as const;
// Use a conditional type to handle the case when we don't have any toggles.
export type Toggle = typeof Toggles extends readonly []
  ? 'dummyToggle' // just a placeholder toggle to make the type non-empty
  : (typeof Toggles)[number];

export function hasToggle(
  customer: Pick<WithId<Customer>, 'toggles'>,
  toggle: Toggle
) {
  return !!customer.toggles?.includes(toggle);
}

// Features are generally whole product experiences that are available
// for customers on certain plans. Certain plans allow for customization
// so not all customers on the same plan will have the same features.
//
// When you remove a FeatureName, remember to run the following script to clean
// up database entries: npm run remove-invalid-customer-settings
export const FeatureNames = [
  'exportContacts',
  'hubspot',
  'lookalikes',
  'salesforce',
  'apiKey',
  'importsTab',
  'selectiveSync',
] as const;
export type FeatureName = (typeof FeatureNames)[number];

// Capabilities are available to all customers of a specific plan.
// They are not customizable.
export const Capabilities = ['zeroWeightSignals'] as const;
export type Capability = (typeof Capabilities)[number];

const ListBuilderPlanSchema = z.object({
  type: z.literal('listBuilder'),
  endDate: z.date(),
  trial: z.boolean().optional(),
});
export type ListBuilderPlan = z.infer<typeof ListBuilderPlanSchema>;

export const PlanSchema = z.union([
  z.object({
    type: z.union([
      z.literal('poc'),
      z.literal('free'),
      z.literal('startup'),
      z.literal('startupPlus'),
      z.literal('growth'),
      z.literal('scale'),
      z.literal('custom'),
    ]),
  }),
  ListBuilderPlanSchema,
]);
export type Plan = z.infer<typeof PlanSchema>;
export type PlanType = Plan['type'];

export const PlanLabels = {
  poc: 'POC',
  free: 'Free',
  listBuilder: 'List Builder',
  startup: 'Startup',
  startupPlus: 'Startup Plus',
  growth: 'Growth',
  scale: 'Scale',
  custom: 'Custom',
} as const satisfies Record<PlanType, string>;
export const PlanTypes = keys(PlanLabels);

export type Limits = {
  activeAccounts: number;
  apiCallsPerMonth: number;
  lookalikes: number;
  recommendedAccounts: number;
  recommendedAccountsSearch: number;
  saves?: number;
  savesPerMonth?: number;
  similarAccountsSearch: number;
  users: number;
  features: FeatureName[];
  capabilities: Capability[];
  markets: number;

  // free-only
  marketPublishes?: number;
};
export type Limit = keyof Limits;

export type StaticLimit = Extract<
  Limit,
  | 'activeAccounts'
  | 'lookalikes'
  | 'recommendedAccounts'
  | 'recommendedAccountsSearch'
  | 'similarAccountsSearch'
  | 'users'
  | 'markets'
>;

export type TrackableLimit = Extract<
  Limit,
  | 'marketPublishes'
  | 'samFilterEdits'
  | 'saves'
  | 'savesPerMonth'
  | 'apiCallsPerMonth'
>;

const listBuilderRecommendedAccounts: LimitFn = (customer) => {
  const limit = customer.limits?.saves?.limit ?? 0;
  const value = customer.limits?.saves?.value ?? 0;
  return _.clamp((limit - value) * 2, 100, 5_000);
};

const listBuilderRecommendedAccountsSearch: LimitFn = (customer) =>
  _.clamp(listBuilderRecommendedAccounts(customer) * 2, 1_000, 10_000);

type LimitFn = (customer: MetadataResponse['customer']) => number;
type FeaturesFn = (customer: MetadataResponse['customer']) => FeatureName[];

export const PlanToPlanLimits: {
  [P in PlanType]: {
    limits: {
      [L in keyof Limits]: L extends Exclude<Limit, 'features' | 'capabilities'>
        ? Limits[L] | LimitFn
        : L extends 'features'
        ? Limits[L] | FeaturesFn
        : Limits[L];
    };
    overridableLimits: Limit[];
  };
} = {
  poc: {
    limits: {
      activeAccounts: 50_000,
      lookalikes: 250,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 5,
      apiCallsPerMonth: 0,
      markets: 3,
      features: ['hubspot', 'salesforce', 'selectiveSync', 'apiKey'],
      capabilities: ['zeroWeightSignals'],
    },
    overridableLimits: [],
  },
  free: {
    limits: {
      activeAccounts: 200,
      lookalikes: 20,
      recommendedAccounts: 50,
      recommendedAccountsSearch: 1_000,
      saves: 25,
      similarAccountsSearch: 1_000,
      users: 10,
      features: [],
      capabilities: [],
      apiCallsPerMonth: 0,
      markets: 1,

      // free specific
      marketPublishes: 10,
    },
    overridableLimits: [],
  },
  listBuilder: {
    limits: {
      activeAccounts: ListBuilderActiveAccountsLimit,
      lookalikes: 50,
      recommendedAccounts: listBuilderRecommendedAccounts,
      recommendedAccountsSearch: listBuilderRecommendedAccountsSearch,
      similarAccountsSearch: listBuilderRecommendedAccountsSearch,
      users: 10,
      features: (customer) =>
        (customer.plan as ListBuilderPlan).trial ? [] : ['hubspot'],
      capabilities: [],
      apiCallsPerMonth: 0,
      markets: 1,
    },
    overridableLimits: ['saves'],
  },
  startup: {
    limits: {
      activeAccounts: 5_000,
      lookalikes: 50,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 25,
      features: ['hubspot'],
      capabilities: [],
      apiCallsPerMonth: 0,
      markets: 1,
    },
    overridableLimits: [],
  },
  startupPlus: {
    limits: {
      activeAccounts: 20_000,
      lookalikes: 50,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 25,
      features: ['hubspot'],
      capabilities: ['zeroWeightSignals'],
      apiCallsPerMonth: 0,
      markets: 1,
    },
    overridableLimits: [],
  },
  growth: {
    limits: {
      activeAccounts: 50_000,
      lookalikes: 250,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 100,
      apiCallsPerMonth: 0,
      markets: 1,
      features: ['hubspot', 'salesforce'],
      capabilities: ['zeroWeightSignals'],
    },
    overridableLimits: ['activeAccounts'],
  },
  scale: {
    limits: {
      activeAccounts: 50_000,
      lookalikes: 250,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 100,
      apiCallsPerMonth: 0,
      markets: 2,
      features: ['hubspot', 'salesforce'],
      capabilities: ['zeroWeightSignals'],
    },
    overridableLimits: ['activeAccounts', 'features', 'markets'],
  },
  custom: {
    limits: {
      activeAccounts: 50_000,
      lookalikes: 250,
      recommendedAccounts: 10_000,
      recommendedAccountsSearch: 10_000,
      similarAccountsSearch: 10_000,
      users: 100,
      apiCallsPerMonth: 5_000,
      markets: 1,
      features: ['hubspot', 'salesforce'],
      capabilities: ['zeroWeightSignals'],
    },
    overridableLimits: [
      'activeAccounts',
      'apiCallsPerMonth',
      'features',
      'lookalikes',
      'markets',
      'recommendedAccounts',
      'recommendedAccountsSearch',
      'savesPerMonth',
    ],
  },
};

export function getSupportedSyncTypes(
  customer: Pick<Customer, 'plan' | 'features'>
): SyncType[] {
  switch (customer.plan.type) {
    case 'free':
      return [];
    case 'listBuilder':
    case 'startup':
      return ['oneWay'];
    case 'startupPlus':
    case 'growth':
      return ['oneWay', 'twoWay'];
    case 'poc':
    case 'scale':
    case 'custom':
      return customer.features?.includes('selectiveSync')
        ? ['oneWay', 'twoWay', 'selectiveTwoWay']
        : ['oneWay', 'twoWay'];
  }
}

export function getFeatures(customer: WithId<Customer>): FeatureName[] {
  const features = PlanToPlanLimits[customer.plan.type].limits.features;
  if (_.isFunction(features)) {
    return features(customer);
  }

  const overridableLimits =
    PlanToPlanLimits[customer.plan.type].overridableLimits;
  if (overridableLimits.includes('features')) {
    return customer.features ?? features;
  }
  return features;
}

export function isCapabilityEnabled(
  customer: Pick<Customer, 'plan'>,
  capability: Capability
) {
  return PlanToPlanLimits[customer.plan.type].limits.capabilities.includes(
    capability
  );
}

export function getCurrentValue(
  customer: Pick<WithId<Customer>, 'limits'>,
  limitName: TrackableLimit
): number {
  return customer.limits?.[limitName]?.value ?? 0;
}

export function getRemaining(
  customer: MetadataResponse['customer'],
  limitName: TrackableLimit
): number {
  const limit = getLimit(customer, limitName);
  if (limit === undefined) {
    return Infinity;
  }

  return limit - getCurrentValue(customer, limitName);
}

// Helper functions to get the relevent saves limit for a customer
export function getSavesLimitName(customer: MetadataResponse['customer']) {
  return customer.plan.type === 'free' || customer.plan.type === 'listBuilder'
    ? 'saves'
    : 'savesPerMonth';
}

export function getSavesQuota(customer: MetadataResponse['customer']) {
  const limitName = getSavesLimitName(customer);
  return {
    current: getCurrentValue(customer, limitName),
    limit: getLimit(customer, limitName),
    remaining: getRemaining(customer, limitName),
  };
}

export function getLimit<L extends Exclude<Limit, 'features' | 'capabilities'>>(
  customer: MetadataResponse['customer'],
  limitName: L
): Limits[L] {
  const limitOrFn = PlanToPlanLimits[customer.plan.type].limits[limitName];
  const planLimit = _.isFunction(limitOrFn)
    ? limitOrFn(customer)
    : (limitOrFn as Limits[L]);

  const overridableLimits =
    PlanToPlanLimits[customer.plan.type].overridableLimits;
  // If the limit is not overridable, return the default plan limit
  if (!overridableLimits.includes(limitName)) {
    return planLimit;
  }

  const customerLimit = customer.limits?.[limitName];
  // Check for static limits first
  if (_.isNumber(customerLimit)) {
    return customerLimit;
  }

  // Otherwise, check for a limit override and fallback to the plan limit
  if (customerLimit?.limit !== undefined) {
    return customerLimit.limit;
  }

  return planLimit;
}

export function isLimitExceeded(
  customer: MetadataResponse['customer'],
  limitName: TrackableLimit,
  increaseBy = 1
): boolean {
  const limit = getLimit(customer, limitName) ?? Infinity;
  const newValue = getCurrentValue(customer, limitName) + increaseBy;

  return newValue > limit;
}

interface TrackedLimit {
  limit?: number;
  value?: number;
}

export type CustomerLimits = Partial<Record<StaticLimit, number>> &
  Partial<Record<TrackableLimit, TrackedLimit>>;

export const CustomerIntegrationSchema = z.union([
  z.literal('hubspot'),
  z.literal('salesforce'),
]);
export type CustomerIntegration = z.infer<typeof CustomerIntegrationSchema>;
export const isCustomerIntegration = zodTypeguard(CustomerIntegrationSchema);

const CreditTransactionBaseSchema = z.object({
  customerId: z.instanceof(ObjectId),
  timestamp: z.date(),
  amount: z.number(),
  description: z.string().optional(),
  type: z.string(),
});

type CreditTransactionBase = z.infer<typeof CreditTransactionBaseSchema>;

const CreditPurchaseSchema = CreditTransactionBaseSchema.extend({
  type: z.literal('purchase'),
  initiatedBy: z.string(),
});
export type CreditPurchase = z.infer<typeof CreditPurchaseSchema>;

const CreditRedemptionBaseSchema = CreditTransactionBaseSchema.extend({
  state: z.enum(['pending', 'completed']),
  type: z.literal('redemption'),
  kind: z.string(),
});

const TaskCreditRedemptionSchema = CreditRedemptionBaseSchema.extend({
  kind: z.literal('task'),
  taskId: z.instanceof(ObjectId),
  initiatedBy: z.string(),
  details: z.object({
    expectedNumberOfEnrichedAccounts: z.number(),
    actualNumberOfEnrichedAccounts: z.number().optional(),
    customEnrichmentRunId: z.instanceof(ObjectId).optional(),
  }),
});
export type TaskCreditRedemption = z.infer<typeof TaskCreditRedemptionSchema>;

const ApiCreditRedemptionSchema = CreditRedemptionBaseSchema.extend({
  kind: z.literal('api'),
  details: z.object({
    expectedNumberOfEnrichedFields: z.number(),
    actualNumberOfEnrichedFields: z.number().optional(),
  }),
});
export type ApiCreditRedemption = z.infer<typeof ApiCreditRedemptionSchema>;

const CreditRedemptionSchema = z.union([
  TaskCreditRedemptionSchema,
  ApiCreditRedemptionSchema,
]);
export type CreditRedemption = z.infer<typeof CreditRedemptionSchema>;

// Used for manually updating customer credits (e.g. by support)
export type ManualCreditTransaction = WithRequired<
  CreditTransactionBase,
  'description'
> & {
  initiatedBy: string;
  type: 'manual';
};

export type CreditTransaction =
  | CreditPurchase
  | CreditRedemption
  | ManualCreditTransaction;

export type Customer = {
  timestamp: Date;
  name: string;
  baseId?: string;
  markets: Market[];
  fields: OptionalAccountField[];
  signals: OptionalScoringSignal[];
  rollups: Rollup[];
  integrations: {
    hubspot?: HubSpotIntegration;
    salesforce?: SalesforceIntegration;
  };

  options?: CustomerOptions;
  features?: FeatureName[];
  toggles?: Toggle[];
  apiKeys: ApiKey[];
  plan: Plan;
  onboarded: boolean;

  limits?: CustomerLimits;
  credits: number;
  transactions: CreditTransaction[];
  fieldDefinitions?: FieldDefinition[];
  signalDefinitions?: SignalDefinition[];
};

/** @see {isCreateCustomerRequest} ts-auto-guard:type-guard */
export type CreateCustomerRequest = Pick<
  Customer,
  'name' | 'plan' | 'limits' | 'features' | 'toggles' | 'onboarded'
>;

/** @see {isEditCustomerRequest} ts-auto-guard:type-guard */
export type EditCustomerRequest = {id: string} & CreateCustomerRequest;

const DeleteCustomerRequestSchema = z.object({
  customerId: z.instanceof(ObjectId),
});
export const isDeleteCustomerRequest = zodTypeguard(
  DeleteCustomerRequestSchema
);

import {useIsMutating, useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  SimilarAccountSizeRequest,
  SimilarAccountSizeResponse,
} from '../../shared/market';
import {useApiMutation} from './api';
import {BulkFindLookalikes} from '../../shared/api/definitions';

type SimilarAccountsSizeProps = {
  enabled?: boolean;
} & SimilarAccountSizeRequest;

// Get the number of similar accounts for the given set of lookalikes.
export function useSimilarAccountsSize({
  enabled,
  lookalikeIds,
}: SimilarAccountsSizeProps) {
  const makeApiCall = useKeyplayApi();
  return useQuery(
    ['similarAccountsSize', JSON.stringify(lookalikeIds)],
    () =>
      makeApiCall<SimilarAccountSizeResponse>('/similarAccountsSize', {
        data: {lookalikeIds},
        method: 'POST',
      }),
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

const BulkdFindLookalikesMutationKey = ['bulkFindLookalikes'];

export const useBulkFindLookalikesMutation = () => {
  return useApiMutation('/bulkFindLookalikes', BulkFindLookalikes, {
    mutationKey: BulkdFindLookalikesMutationKey,
  });
};

export const useIsBulkFindLookalikesLoading = () =>
  useIsMutating(BulkdFindLookalikesMutationKey) > 0;

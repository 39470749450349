import {ObjectId} from 'bson';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';

const TaskTypeSchema = z.union([
  // customer facing
  z.literal('airtableCRMImport'),
  z.literal('exportCsv'),
  z.literal('exportToClay'),
  z.literal('exportContacts'),
  z.literal('fileCRMImport'),
  z.literal('hubSpotEnrich'),
  z.literal('hubSpotPushSavedAccounts'),
  z.literal('hubSpotPullAndSync'),
  z.literal('hubSpotReverseEnrich'),
  z.literal('hubSpotFullRun'),
  z.literal('recommendedAccountsImport'),
  z.literal('refreshAccounts'),
  z.literal('roleCountEnrichment'),
  z.literal('salesforceEnrich'),
  z.literal('salesforcePushSavedAccounts'),
  z.literal('salesforcePullAndSync'),
  z.literal('salesforceReverseEnrich'),
  z.literal('salesforceFullRun'),
  z.literal('syncAirtableAccounts'),
  z.literal('validateModelGroup'),

  // internal
  z.literal('cleanupTasks'),
  z.literal('crmSyncCustomers'),
  z.literal('refreshCustomers'),
  z.literal('refreshCustomerLimits'),
  z.literal('refreshScoringData'),
  z.literal('removeOldScoringRuns'),
  z.literal('syncOverrides'),
  z.literal('syncRedirectReviews'),
]);
export type TaskType = z.infer<typeof TaskTypeSchema>;

const TaskStateSchema = z.union([
  z.literal('pending'),
  z.literal('running'),
  z.literal('done'),
  z.literal('failed'),
  z.literal('canceled'),
]);
export type TaskState = z.infer<typeof TaskStateSchema>;

// Detailed task state needs to be serializable into something that can be written
// to and read from mongo. TODO: is there a better type for this?
export type DetailedTaskState = {
  [key: string]:
    | number
    | string
    | boolean
    | ObjectId
    | Date
    | null
    | undefined
    | (number | string | boolean | ObjectId | Date)[]
    | DetailedTaskState;
};

export const DetailedTaskStateSchema: z.ZodType<DetailedTaskState> = z.lazy(
  () =>
    z.record(
      z.union([
        z.string(),
        z.number(),
        z.boolean(),
        z.instanceof(ObjectId),
        z.date(),
        z.null(),
        z.undefined(),
        z.array(
          z.union([
            z.string(),
            z.number(),
            z.boolean(),
            z.instanceof(ObjectId),
            z.date(),
          ])
        ),
        DetailedTaskStateSchema,
      ])
    )
);

const FetchStatusSchema = z.object({
  fetchErrors: z.number(),
  otherErrors: z.number(),
  ready: z.number(),
});
export const isFetchStatus = zodTypeguard(FetchStatusSchema);
export type FetchStatus = z.infer<typeof FetchStatusSchema>;

const TaskUpdateSchema = z.object({
  timestamp: z.date(),
  state: TaskStateSchema.optional(),
  error: z.string().optional(),
  detailedState: DetailedTaskStateSchema.optional(),
  fetchStatus: FetchStatusSchema.optional(),
});
export type TaskUpdate = z.infer<typeof TaskUpdateSchema>;

const TaskSchema = z.object({
  timestamp: z.date(),
  fullName: z.string(),
  input: z.record(z.unknown()),
  name: z.string(),
  type: TaskTypeSchema,

  // These fields are shortcuts so we don't have to look through updates for
  // the most recent write to a given field.
  state: TaskStateSchema,
  fetchStatus: FetchStatusSchema.optional(),
  error: z.string().optional(),

  pendingCancel: z.boolean().optional(),
  // only one task with this ID can run at a time
  concurrencyConstraint: z.string().optional(),
  // only one task with this ID can be pending or running at a time
  existenceConstraint: z.string().optional(),

  updates: z.array(TaskUpdateSchema).optional(),

  // Store additional data that might be useful for internal use cases
  debug: z
    .object({
      // TODO: convert Customer type to zod schema
      customer: z
        .object({
          name: z.string(),
        })
        .optional(),
    })
    .optional(),
});

// Helper function to create a task schema with a detailed state.
// - https://stackoverflow.com/questions/74907523/creating-zod-schema-for-generic-interface
// - https://github.com/colinhacks/zod#writing-generic-functions
export const createTaskSchema = <T extends z.ZodType<DetailedTaskState>>(
  detailedState: T
) => {
  return TaskSchema.extend({
    detailedState: detailedState.optional(),
  });
};

export const GenericTaskSchema = createTaskSchema(DetailedTaskStateSchema);
export type GenericTask = z.infer<typeof GenericTaskSchema>;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export type TaskTempData<T extends unknown = unknown> = {
  data: T;
  taskId: ObjectId;
  timestamp: Date;
};

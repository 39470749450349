import React from 'react';
import {Box, Flex, Grid, Switch, Text, Tooltip} from '@chakra-ui/react';
import {CustomerIntegration} from '../../shared/customer';
import {
  EnrichmentFieldLabels,
  StandardEnrichmentField,
  StandardEnrichmentFieldConfig,
  StandardEnrichmentFieldCrmLabels,
  StandardEnrichmentFieldCrmNames,
  isStandardEnrichmentField,
} from '../../shared/integrations';
import {Integration} from './AllIntegrations';
import {captureMessage} from '@sentry/react';
import {
  GridCell,
  GridHeader,
  IntegrationSection,
  gridTemplateColumns,
} from './components';
import {ChevronRight} from '@carbon/icons-react';
import {useIntegrationDetailsState} from './IntegrationDetails.state';

export const StandardEnrichmentFieldMapping = ({
  integrationName,
  integrationInfo,
  availableEnrichmentFields,
  enrichmentFields,
}: {
  integrationName: CustomerIntegration;
  integrationInfo: Integration;
  availableEnrichmentFields: StandardEnrichmentField[];
  enrichmentFields: StandardEnrichmentFieldConfig[];
}) => {
  const {addStandardField, deleteStandardField, publishChanges} =
    useIntegrationDetailsState(integrationName);

  // Use all standard fields as the source to drive the list of field mappings to display.
  // For each one, if there exists an existing mapping, use its configuration.
  const mergedFieldConfigs: StandardEnrichmentFieldConfig[] =
    availableEnrichmentFields.map((field) => {
      const existingField = enrichmentFields.find(
        (enrichmentField) => enrichmentField.enrichmentField === field
      );

      return {
        crmFieldName: getCrmFieldName(field, integrationName),
        enrichmentField: field,
        enrichmentFieldType: 'standard',
        kind: 'keyplay',
        enabled: !!existingField?.enabled,
      };
    });

  const onUpdateEnrichmentField = (
    fieldConfig: StandardEnrichmentFieldConfig,
    enabled: boolean
  ) => {
    if (enabled) {
      addStandardField({
        ...fieldConfig,
        enabled,
      });
      publishChanges.mutate();
    } else {
      deleteStandardField(fieldConfig.enrichmentField);
      publishChanges.mutate();
    }
  };

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Standard Enrichment Fields
      </Box>
      <Text mb={10}>
        When enabling a standard field, Keyplay will create the field
        automatically on your {integrationInfo.label} company record within a
        “Keyplay Enrichment” field group. Values are refreshed weekly.
      </Text>

      <Grid templateColumns={gridTemplateColumns} gap={2}>
        <GridHeader></GridHeader>
        <GridHeader>Keyplay Field</GridHeader>
        <GridHeader></GridHeader>
        <GridHeader>{integrationInfo.label} Field</GridHeader>

        {mergedFieldConfigs.map((fieldConfig) => (
          <StandardEnrichmentFieldGridRow
            key={fieldConfig.enrichmentField}
            fieldConfig={fieldConfig}
            onUpdateEnrichmentField={onUpdateEnrichmentField}
          />
        ))}
      </Grid>
    </IntegrationSection>
  );
};

const CrmFieldCell = ({name, label}: {name: string; label: string}) => {
  return (
    <Flex direction="column">
      <Tooltip label={name} placement="top">
        <Box>{label}</Box>
      </Tooltip>
    </Flex>
  );
};

const getCrmFieldName = (
  field: StandardEnrichmentField,
  integrationName: CustomerIntegration
): string => {
  const fieldValue = StandardEnrichmentFieldCrmNames[field];

  if (integrationName === 'salesforce') {
    // Convert string format for Salesforce
    // Camel case (with first work capitalized) and add __c
    const words = fieldValue.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join('') + '__c';
  }

  // Return original string for HubSpot
  return fieldValue;
};

const StandardEnrichmentFieldGridRow = ({
  fieldConfig,
  onUpdateEnrichmentField,
}: {
  fieldConfig: StandardEnrichmentFieldConfig;
  onUpdateEnrichmentField: (
    enrichmentField: StandardEnrichmentFieldConfig,
    enabled: boolean
  ) => void;
}) => {
  // This should never happen, but we need a typeguard so we can index into the
  // StandardEnrichmentFieldCrmLabels object without a type error.
  if (!isStandardEnrichmentField(fieldConfig.enrichmentField)) {
    captureMessage('Invalid standard enrichment field', {
      level: 'error',
      extra: {fieldConfig},
    });
    return null;
  }

  return (
    <>
      <GridCell>
        <Switch
          isChecked={fieldConfig.enabled}
          onChange={(event) => {
            onUpdateEnrichmentField(fieldConfig, event.target.checked);
          }}
        />
      </GridCell>
      <GridCell hasBorder>
        {EnrichmentFieldLabels[fieldConfig.enrichmentField]}
      </GridCell>
      <GridCell>
        <ChevronRight />
      </GridCell>
      <GridCell hasBorder>
        <CrmFieldCell
          name={fieldConfig.crmFieldName}
          label={StandardEnrichmentFieldCrmLabels[fieldConfig.enrichmentField]}
        />
      </GridCell>
    </>
  );
};

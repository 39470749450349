import {useMutation} from '@tanstack/react-query';
import {useSimilarityState} from '../ModelBuilder/Similarity.state';
import {useScoringModelSignalStore} from '../ModelBuilder/SignalAssignmentStore';
import {useMarketFromContext} from '../MarketProvider';
import {useSamEditorStore} from '../../SamEditor/useSamEditorStore';
import {useIsRescoring, useScoringInfo} from '../../../hooks/api/scoringInfo';
import {usePublishMarket} from '../../../hooks/api/markets';
import {useSamDefinitionLimits} from '../../SamEditor/useSamDefinitionLimits';

export const useHasMarketBuilderChanges = () => {
  const similarityStore = useSimilarityState();
  const signalStore = useScoringModelSignalStore();
  const samEditorStore = useSamEditorStore();

  return (
    similarityStore.hasChanges() ||
    signalStore.hasBucketChanges() ||
    signalStore.hasWeightChanges() ||
    samEditorStore.hasChanges()
  );
};

export const useCanSaveMarketBuilder = () => {
  const samEditorStore = useSamEditorStore();
  const samLimits = useSamDefinitionLimits();

  const isRescoring = useIsRescoring();

  const disableSave = [
    {
      condition: isRescoring,
      message: 'Saving is disabled while account refresh is in progress',
    },
    {
      condition: samEditorStore.isEmpty(),
      message: 'Serviceable Market filters cannot be empty',
    },
    {
      condition: !samLimits.withinLimits,
      message:
        'Expand your Serviceable Market filters to include more accounts',
    },
    {
      condition: samLimits.isInitialLoading,
      message: '',
    },
  ].find(({condition}) => condition);

  return disableSave
    ? {
        disableSave: true,
        errorMessage: disableSave.message,
      }
    : {
        disableSave: false,
        errorMessage: '',
      };
};

export const useCanPublishMarketBuilder = () => {
  const {lastModified} = useMarketFromContext();
  const {isInitialLoading, data: scoringInfo} = useScoringInfo();
  const {disableSave, errorMessage: saveErrorMessage} =
    useCanSaveMarketBuilder();

  const lastRefresh = scoringInfo?.latestRefreshTimestamp;
  const disablePublish = [
    {
      condition:
        lastRefresh &&
        lastModified &&
        lastRefresh.getTime() > lastModified.getTime(),
      message: 'No changes to publish',
    },
    {
      condition: disableSave,
      message: saveErrorMessage,
    },
    {
      condition: isInitialLoading,
      message: '',
    },
  ].find(({condition}) => condition);

  return disablePublish
    ? {
        disablePublish: true,
        errorMessage: disablePublish.message,
      }
    : {
        disablePublish: false,
        errorMessage: '',
      };
};

export const useSaveMarketBuilder = ({
  triggerRefresh,
}: {
  triggerRefresh: boolean;
}) => {
  const similarityStore = useSimilarityState();
  const signalStore = useScoringModelSignalStore();
  const samEditorStore = useSamEditorStore();

  const {id: marketId} = useMarketFromContext();
  const publishMarket = usePublishMarket();

  // Return a proxy mutation that passes in each store's local state to the scoring model request.
  // Then updates each store with the server state on success.
  return useMutation({
    mutationFn: () => {
      if (
        !similarityStore.hasInitialized ||
        !signalStore.hasInitialized ||
        !samEditorStore.hasInitialized
      ) {
        throw new Error('Cannot publish changes before initializing');
      }

      return publishMarket.mutateAsync({
        marketId,
        samDefinition: samEditorStore.toServerState(),
        scoringModel: {
          lookalikes: similarityStore.localState.map(({_id}) => _id),
          signalsWithWeights: signalStore.getServerFormat(),
        },
        triggerRefresh,
      });
    },
    onSuccess: async ({scoringModel}) => {
      // Note: the similarity store automatically updates its state on change.
      if (scoringModel.signalsWithWeights) {
        signalStore.persistAllChanges(scoringModel.signalsWithWeights);
      }
    },
  });
};

import {ObjectId} from 'bson';
import {KeyplayScoringSignal, ScoringCategory} from './signals';
import _ from 'lodash';
import {RoleSearchCountrySchema} from './countries';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {AccountField} from './fields';
import {assertNever, constructZodEnumType} from './util';

export type CustomFields = Record<string, unknown>;

export const FieldDataTypes = ['number', 'string'] as const;
const FieldDataTypeSchema = constructZodEnumType(FieldDataTypes);

const BaseFieldDefinitionSchema = z.object({
  id: z.instanceof(ObjectId),
  label: z.string(),
  dataType: FieldDataTypeSchema,
  timestamp: z.date(),

  // overridden by subtypes
  config: z.object({}),
  type: z.string(),
});

const RoleCountFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('roleCount'),
  config: z.object({
    include: z.array(z.string()),
    exclude: z.array(z.string()),
    titles: z.array(z.string()),
    countries: z.array(RoleSearchCountrySchema),
  }),
});
export type RoleCountFieldDefinition = z.infer<
  typeof RoleCountFieldDefinitionSchema
>;

const CrmFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('crm'),
  config: z.object({
    fieldName: z.string(),
    integrationId: z.instanceof(ObjectId),
  }),
});
export type CrmFieldDefinition = z.infer<typeof CrmFieldDefinitionSchema>;
export const isCrmFieldDefinition = zodTypeguard(CrmFieldDefinitionSchema);

export type FieldDefinition = RoleCountFieldDefinition | CrmFieldDefinition;

export const FieldDefinitionTypeLabels: Record<
  FieldDefinition['type'],
  string
> = {
  crm: 'CRM Field',
  roleCount: 'Role Count',
};

export interface CustomEnrichmentRun {
  customerId: ObjectId;
  fieldDefinition: FieldDefinition;
  expectedNumberOfEnrichedAccounts: number;
  actualNumberOfEnrichedAccounts: number;
  timestamp: Date;
}

// HACK: we don't currently have a universal concept of a parent EnrichmentFieldAction.
// So in the meantime we just use this as a way to group enrichment values that were
// generated together. https://github.com/keyplay-io/bfdb/pull/1572 has some discussion
// and prototyping around what we might want to implement in the future
export type EnrichmentSource =
  | {type: 'enrichmentRun'; id: ObjectId}
  | {type: 'api'; id: ObjectId};

export interface EnrichedFieldValue {
  customerId: ObjectId;
  accountId: ObjectId;
  fieldDefinitionId: ObjectId;

  source: EnrichmentSource;

  timestamp: Date;
  value: unknown;
}

export interface NumberEvalFn {
  field: ObjectId;
  operator: '>' | '>=' | '<' | '<=' | '=' | '!=';
  comparator: number;
  type: 'number';
}

export interface RangeEvalFn {
  field: ObjectId | AccountField;
  lowerBound?: number; // inclusive
  upperBound?: number; // inclusive
  type: 'range';
}

export interface LogicalOrEvalFn {
  signalEvalFns: SignalEvalFn[];
  type: 'or';
  minCount?: number;
}

export interface LogicalAndEvalFn {
  signalEvalFns: SignalEvalFn[];
  type: 'and';
}

export interface KeyplaySignalEvalFn {
  signal: KeyplayScoringSignal;
  type: 'keyplaySignal';
}

export type SignalEvalFn =
  | NumberEvalFn
  | RangeEvalFn
  | LogicalOrEvalFn
  | LogicalAndEvalFn
  | KeyplaySignalEvalFn;

export interface SignalDefinition {
  id: string;
  label: string;
  category: ScoringCategory;
  evalFn: SignalEvalFn;
  timestamp: Date;
  type: 'local' | 'global';
}

export type SignalEvalFnInput =
  | {
      field: ObjectId | AccountField;
      type: 'field';
    }
  | {
      signal: KeyplayScoringSignal;
      type: 'signal';
    };

export function getSignalEvalFnInputs(
  evalFn: SignalEvalFn
): SignalEvalFnInput[] {
  const {type} = evalFn;
  switch (type) {
    case 'number':
    case 'range':
      return [
        {
          field: evalFn.field,
          type: 'field',
        },
      ];

    case 'keyplaySignal':
      return [
        {
          signal: evalFn.signal,
          type: 'signal',
        },
      ];

    case 'or':
    case 'and':
      return _.flatten(evalFn.signalEvalFns.map(getSignalEvalFnInputs));

    default:
      assertNever(type);
  }
}

import React, {useContext, useEffect, useState} from 'react';
import {Box, Flex, Image, Link, Spinner} from '@chakra-ui/react';
import {
  Routes,
  Route,
  useNavigate,
  matchPath,
  useLocation,
  Navigate,
} from 'react-router';
import {useLogout} from '../../hooks/api/logout';
import _ from 'lodash';
import {GetStarted} from './GetStarted';
import {LookalikeSelection} from './LookalikeSelection';
import {SetRole} from './SetRole';
import {track} from '../../analytics';
import {useAuth0} from '@auth0/auth0-react';
import {useCompleteOnboarding} from '../../hooks/api/customer';
import {useDefaultMarketId} from '../../hooks/api/metadata';
import {MarketProvider} from '../Market/MarketProvider';
import {TemplatePicker} from './TemplatePicker';
import {useOnboardingSteps} from './OnboardingSteps';
import {SamFilter} from './SamFilter';
import {useMarket} from '../../hooks/api/markets';

type Navigate = (dir: 'next' | 'prev') => void;
export type OnboardingContext = {
  onPageNavigate: Navigate;
  isFirstStep: boolean;
};

const OnboardingContext = React.createContext<OnboardingContext | undefined>(
  undefined
);

export function useOnboardingContext() {
  const onboardingContext = useContext(OnboardingContext);
  if (!onboardingContext) {
    throw new Error('could not find OnboardingContext');
  }

  return onboardingContext;
}

export const Onboarding = () => {
  const {user} = useAuth0();
  const logout = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);

  const completeOnboarding = useCompleteOnboarding();
  const marketId = useDefaultMarketId();
  const market = useMarket({marketId: marketId.toString()});
  const onboardingSteps = useOnboardingSteps();

  useEffect(() => {
    setPage(
      onboardingSteps.findIndex((step) =>
        matchPath(location.pathname, `/onboarding/${step.route}`)
      )
    );
  }, [location.pathname, onboardingSteps]);
  const step = onboardingSteps[page];

  const onPageNavigate: Navigate = (dir) => {
    const nextPage = dir === 'next' ? page + 1 : page - 1;
    const nextStep = onboardingSteps.at(nextPage);
    if (!nextStep) {
      if (dir === 'next') {
        // Complete onboarding if there are no more steps
        completeOnboarding.mutate();
      }
      return;
    }

    const {name, route} = nextStep;
    track('onboardingAdvanced', {
      user,
      properties: {name: name, path: route, version: 'v2'},
    });
    navigate(nextStep.route, {relative: 'route'});
  };

  if (completeOnboarding.isLoading) {
    return (
      <Flex
        bgColor={'kgray.100'}
        h="100%"
        w="100%"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Spinner />
      </Flex>
    );
  }

  const onboardingContext = {
    onPageNavigate,
    isFirstStep: page === 1,
  };

  return (
    <Flex bgColor={'kgray.100'} h="100%" w="100%" overflow={'auto'} p={4}>
      <Flex
        bgColor={'white'}
        borderRadius={'xl'}
        direction="column"
        position="relative"
        w={step.sidebar ? {base: '100%', xl: '70%'} : '100%'}
      >
        <Flex alignItems={'baseline'} justifyContent={'space-between'} p={6}>
          <PageIndicator page={page} totalPages={onboardingSteps.length} />
          <Link color="kgray.200" fontSize="xs" onClick={() => logout.mutate()}>
            Logout
          </Link>
        </Flex>

        {!market.data ? (
          <></>
        ) : (
          <MarketProvider market={market.data}>
            <OnboardingContext.Provider value={onboardingContext}>
              <Flex position={'relative'} grow={1} h="100%">
                <Routes>
                  <Route path="/" element={<GetStarted />} />
                  <Route path="/template" element={<TemplatePicker />} />
                  <Route path="/filters" element={<SamFilter />} />
                  <Route path="/lookalikes" element={<LookalikeSelection />} />
                  <Route path="/role" element={<SetRole />} />
                  <Route
                    path="*"
                    element={<Navigate to="/" replace={true} />}
                  />
                </Routes>
              </Flex>
            </OnboardingContext.Provider>
          </MarketProvider>
        )}
      </Flex>

      {step.sidebar && (
        <Flex
          alignItems={'center'}
          direction="column"
          display={{base: 'none', xl: 'flex'}}
          justifyContent={'center'}
          mx={14}
          gap={4}
          w={'30%'}
        >
          <Image src={step.sidebar.image} w="100%" minW="355px" maxW="475px" />
          <Box textAlign={'center'} color={'kgray.300'}>
            {step.sidebar.text}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

const PageIndicator = ({
  page,
  totalPages,
}: {
  page: number;
  totalPages: number;
}) => {
  return (
    <Flex mb={0} gap={1}>
      {_.times(totalPages, (pageNumber) => {
        const isCurrentPage = pageNumber === page;
        // TODO: Investigate how to add a cool transition effect to the pills
        return (
          <Box
            backgroundColor={isCurrentPage ? 'kred.400' : 'kgray.200'}
            borderRadius={'full'}
            height="8px"
            key={pageNumber}
            width={isCurrentPage ? '20px' : '8px'}
          />
        );
      })}
    </Flex>
  );
};

import {ObjectId} from 'bson';
import {MarketSchema, ScoringModelSchema} from '../market';
import {ScoringRun, ScoredAccount} from '../scoredAccounts';
import {ScoringSignalSchema} from '../signals';
import {WithId} from '../util';
import {z} from 'zod';

export const AccountWithLogoSchema = z.object({
  _id: z.instanceof(ObjectId),
  name: z.string(),
  domain: z.string(),
  logoUrl: z.string().optional(),
});
export type AccountWithLogo = z.infer<typeof AccountWithLogoSchema>;
export type AccountTypeaheadResponse = AccountWithLogo[];

const ScoringModelWithLookalikeLogosSchema = ScoringModelSchema.extend({
  lookalikesWithLogos: z.array(AccountWithLogoSchema),
});
export type ScoringModelWithLookalikeLogos = z.infer<
  typeof ScoringModelWithLookalikeLogosSchema
>;

export const GetMarketResponseSchema = MarketSchema.extend({
  scoringModel: ScoringModelWithLookalikeLogosSchema,
});
export type GetMarketResponse = z.infer<typeof GetMarketResponseSchema>;

export type ScoringRunResponse = WithId<
  Pick<ScoringRun, 'samSize' | 'lookalikesWithLogos'>
> & {numActive: number};

export interface ScoredAccountsResponse {
  accounts: ScoredAccount[];
  cursor?: string;
  scoringRun?: ScoringRunResponse;
  timestamp?: Date;
  totalLength?: number;
}

const AccountSignalsResponseSchema = z.array(ScoringSignalSchema);
export type AccountSignalsResponse = z.infer<
  typeof AccountSignalsResponseSchema
>;

export const AccountSimilarityScoreResponseSchema = z
  .object({
    bestScore: z.number(),
    bestLookalike: AccountWithLogoSchema,
  })
  .optional();
export type AccountSimilarityScoreResponse = z.infer<
  typeof AccountSimilarityScoreResponseSchema
>;

export const UnsyncedCountRequestSchema = z.object({
  runId: z.string(),
});
export type UnsyncedCountRequest = z.infer<typeof UnsyncedCountRequestSchema>;
export interface UnsyncedCountResponse {
  unsyncedCount: number;
}

export interface ScoringInfoResponse {
  isRescoring: boolean;
  latestRefreshTimestamp?: Date;
}

const CreditPriceSchema = z.object({
  amount: z.number(),
  credits: z.number(),
  priceId: z.string(),
});
export const CreditPriceResponseSchema = z.array(CreditPriceSchema);

import {createStore, StoreApi, useStore} from 'zustand';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from '../../shared/account';
import {ActiveCountryList, CountryCode} from '../../shared/countries';
import {SamDefinition} from '../../shared/market';
import {
  CoreScoringSignals,
  CoreStackFitSignals,
  KeyplayScoringSignal,
  ScoringOnlySignals,
  ScoringSignalToLabel,
  StackFitSignal,
  StackFitSignals,
} from '../../shared/signals';
import {devtools} from 'zustand/middleware';
import produce from 'immer';
import _ from 'lodash';
import {useEffect} from 'react';
import {useCustomer} from '../../hooks/api/metadata';
import {fromEntriesMapped, keys} from '../../shared/util';
import {useMutation} from '@tanstack/react-query';
import {useMarketFromContext} from '../Market/MarketProvider';
import {usePublishMarket} from '../../hooks/api/markets';

export const LocationLabels = {
  hq: 'HQ',
  offices: 'offices',
} as const;

export type LocationType = keyof typeof LocationLabels;

interface SamEditorState {
  hasInitialized: boolean;
  toServerState: () => SamDefinition;
  updateServerState: (
    samDefinition: SamDefinition,
    signalOptions: {[signal in KeyplayScoringSignal]?: string},
    stackFitOptions: {[signal in KeyplayScoringSignal]?: string}
  ) => void;
  serverState: SamDefinition | null;
  signalOptions: {[signal in KeyplayScoringSignal]?: string};
  stackFitOptions: {[signal in KeyplayScoringSignal]?: string};

  audience: BusinessAudience[];
  setAudience: (audience: BusinessAudience[]) => void;
  minEmployees: number | undefined;
  setMinEmployees: (minEmployees: number | undefined) => void;
  maxEmployees: number | undefined;
  setMaxEmployees: (maxEmployees: number | undefined) => void;
  locations: CountryCode[];
  setLocations: (locations: CountryCode[]) => void;
  locationsType: 'hq' | 'offices';
  setLocationsType: (locationType: 'hq' | 'offices') => void;
  advancedSignalGroups: KeyplayScoringSignal[][];
  setAdvancedSignalGroups: (matchGroups: KeyplayScoringSignal[][]) => void;
  primaryCategories: PrimaryBusinessCategory[];
  setPrimaryCategories: (primaryCategories: PrimaryBusinessCategory[]) => void;
  secondaryCategories: SecondaryBusinessCategory[];
  setSecondaryCategories: (
    secondaryCategories: SecondaryBusinessCategory[]
  ) => void;
  stackFitSignals: StackFitSignal[];
  setStackFitSignals: (stackFitSignals: StackFitSignal[]) => void;

  // Exclusions
  excludedPrimaryCategories: PrimaryBusinessCategory[] | undefined;
  setExcludedPrimaryCategories: (
    primaryCategoryExcludes: PrimaryBusinessCategory[] | undefined
  ) => void;
  excludedSecondaryCategories: SecondaryBusinessCategory[] | undefined;
  setExcludedSecondaryCategories: (
    secondaryCategoryExcludes: SecondaryBusinessCategory[] | undefined
  ) => void;

  hasChanges: () => boolean;
  isEmpty: () => boolean;
  resetState: () => void;
}

// If the customer has signals in their SAM they don't have access to, we filter them
// out here so they don't get an error when they save. This shouldn't happen very often.
const filterUnavailable = <T>(data: T[] | undefined, validOptions: T[]) =>
  _.intersection(data, validOptions);

const createSamEditorStore = () =>
  createStore<SamEditorState>()(
    devtools<SamEditorState>((set, get) => {
      const toLocalState = (samDefinition: SamDefinition) => {
        let locations: CountryCode[] = [];
        let locationsType: 'hq' | 'offices' = 'offices';

        if (samDefinition.locations?.length) {
          locations = samDefinition.locations;
          locationsType = 'offices';
        } else if (samDefinition.hqCountries?.length) {
          locations = samDefinition.hqCountries;
          locationsType = 'hq';
        }

        return {
          audience: samDefinition.audience ?? [],
          minEmployees: samDefinition.employees?.min,
          maxEmployees: samDefinition.employees?.max,
          locations,
          locationsType,
          matchGroups: samDefinition.matchGroups ?? [],
          primaryCategories: samDefinition.primaryCategory ?? [],
          secondaryCategories: samDefinition.secondaryCategory ?? [],
          stackFitSignals: samDefinition.stackFitSignals ?? [],
          excludedPrimaryCategories: samDefinition.excludes?.primaryCategory,
          excludedSecondaryCategories:
            samDefinition.excludes?.secondaryCategory,
        };
      };

      const setLocalState = (
        samDefinition: SamDefinition,
        signalOptions: {[signal in KeyplayScoringSignal]?: string},
        stackFitOptions: {[signal in StackFitSignal]?: string}
      ) => {
        const updatedState = toLocalState(samDefinition);

        set(
          produce<SamEditorState>((state) => {
            state.audience = updatedState.audience;
            state.locations = filterUnavailable(
              updatedState.locations,
              keys(ActiveCountryList)
            );
            state.locationsType = updatedState.locationsType;
            state.maxEmployees = updatedState.maxEmployees;
            state.minEmployees = updatedState.minEmployees;
            state.primaryCategories = updatedState.primaryCategories;
            state.excludedPrimaryCategories =
              updatedState.excludedPrimaryCategories;
            state.secondaryCategories = updatedState.secondaryCategories;
            state.excludedSecondaryCategories =
              updatedState.excludedSecondaryCategories;
            state.advancedSignalGroups = updatedState.matchGroups.map(
              (matchGroup) => filterUnavailable(matchGroup, keys(signalOptions))
            );
            state.stackFitSignals = filterUnavailable(
              updatedState.stackFitSignals,
              keys(stackFitOptions)
            );
          })
        );
      };

      return {
        hasInitialized: false,
        toServerState: () => {
          return {
            audience: get().audience,
            employees: {
              min: get().minEmployees,
              max: get().maxEmployees,
            },
            hqCountries: get().locationsType === 'hq' ? get().locations : [],
            locations: get().locationsType === 'offices' ? get().locations : [],
            matchGroups: get().advancedSignalGroups,
            primaryCategory: get().primaryCategories,
            secondaryCategory: get().secondaryCategories,
            stackFitSignals: get().stackFitSignals,
            excludes: {
              primaryCategory: get().excludedPrimaryCategories,
              secondaryCategory: get().excludedSecondaryCategories,
            },
          };
        },
        updateServerState: (samDefinition, signalOptions, stackFitOptions) => {
          if (
            !get().hasChanges() &&
            // Only update if the server state has changed to maintain stable references
            !_.isEqual(samDefinition, get().serverState)
          ) {
            setLocalState(samDefinition, signalOptions, stackFitOptions);
          }

          set(
            produce<SamEditorState>((state) => {
              state.serverState = samDefinition;
              state.signalOptions = signalOptions;
              state.stackFitOptions = stackFitOptions;
              state.hasInitialized = true;
            })
          );
        },
        serverState: null,
        signalOptions: {},
        stackFitOptions: {},

        audience: [],
        setAudience: (audience) =>
          set(
            produce<SamEditorState>((state) => {
              state.audience = audience;
            })
          ),
        minEmployees: undefined,
        setMinEmployees: (minEmployees) =>
          set(
            produce<SamEditorState>((state) => {
              state.minEmployees = minEmployees;
            })
          ),
        maxEmployees: undefined,
        setMaxEmployees: (maxEmployees) =>
          set(
            produce<SamEditorState>((state) => {
              state.maxEmployees = maxEmployees;
            })
          ),
        locations: [],
        setLocations: (locations) =>
          set(
            produce<SamEditorState>((state) => {
              state.locations = locations;
            })
          ),
        locationsType: 'offices',
        setLocationsType: (locationType) =>
          set(
            produce<SamEditorState>((state) => {
              state.locationsType = locationType;
            })
          ),
        advancedSignalGroups: [],
        setAdvancedSignalGroups: (matchGroups) =>
          set(
            produce<SamEditorState>((state) => {
              state.advancedSignalGroups = matchGroups;
            })
          ),
        primaryCategories: [],
        setPrimaryCategories: (primaryCategories) =>
          set(
            produce<SamEditorState>((state) => {
              state.primaryCategories = primaryCategories;
            })
          ),
        secondaryCategories: [],
        setSecondaryCategories: (secondaryCategories) =>
          set(
            produce<SamEditorState>((state) => {
              state.secondaryCategories = secondaryCategories;
            })
          ),
        stackFitSignals: [],
        setStackFitSignals: (stackFitSignals) =>
          set(
            produce<SamEditorState>((state) => {
              state.stackFitSignals = stackFitSignals;
            })
          ),

        excludedPrimaryCategories: undefined,
        setExcludedPrimaryCategories: (primaryCategoryExcludes) =>
          set(
            produce<SamEditorState>((state) => {
              state.excludedPrimaryCategories = primaryCategoryExcludes;
            })
          ),
        excludedSecondaryCategories: undefined,
        setExcludedSecondaryCategories: (secondaryCategoryExcludes) =>
          set(
            produce<SamEditorState>((state) => {
              state.excludedSecondaryCategories = secondaryCategoryExcludes;
            })
          ),

        hasChanges: () => {
          const samDefinition = get().serverState;
          if (!get().hasInitialized || !samDefinition) {
            return false;
          }

          const {
            audience,
            locations,
            locationsType,
            matchGroups,
            maxEmployees,
            minEmployees,
            primaryCategories,
            excludedPrimaryCategories: primaryCategoryExcludes,
            secondaryCategories,
            excludedSecondaryCategories: secondaryCategoryExcludes,
            stackFitSignals,
          } = toLocalState(samDefinition);

          return (
            !_.isEqual(audience, get().audience) ||
            !_.isEqual(locations, get().locations) ||
            !_.isEqual(locationsType, get().locationsType) ||
            !_.isEqual(matchGroups, get().advancedSignalGroups) ||
            !_.isEqual(maxEmployees, get().maxEmployees) ||
            !_.isEqual(minEmployees, get().minEmployees) ||
            !_.isEqual(primaryCategories, get().primaryCategories) ||
            !_.isEqual(
              primaryCategoryExcludes,
              get().excludedPrimaryCategories
            ) ||
            !_.isEqual(secondaryCategories, get().secondaryCategories) ||
            !_.isEqual(
              secondaryCategoryExcludes,
              get().excludedSecondaryCategories
            ) ||
            !_.isEqual(stackFitSignals, get().stackFitSignals)
          );
        },
        isEmpty: () => {
          return (
            _.isEmpty(get().audience) &&
            _.isEmpty(get().locations) &&
            _.isUndefined(get().minEmployees) &&
            _.isUndefined(get().maxEmployees) &&
            _.isEmpty(get().advancedSignalGroups.flat()) &&
            _.isEmpty(get().primaryCategories) &&
            _.isEmpty(get().secondaryCategories) &&
            _.isEmpty(get().stackFitSignals) &&
            _.isEmpty(get().excludedPrimaryCategories) &&
            _.isEmpty(get().excludedSecondaryCategories)
          );
        },
        resetState: () => {
          const samDefinition = get().serverState;
          if (!get().hasInitialized || !samDefinition) {
            return;
          }

          setLocalState(
            samDefinition,
            get().signalOptions,
            get().stackFitOptions
          );
        },
      };
    })
  );

// One store per market
const samEditorStores: Record<string, StoreApi<SamEditorState>> = {};

export const useSamEditorStore = () => {
  const {id: marketId, samDefinition} = useMarketFromContext();
  const customer = useCustomer();

  let samEditorStore = samEditorStores[marketId.toString()];
  if (!samEditorStore) {
    samEditorStore = createSamEditorStore();
    samEditorStores[marketId.toString()] = samEditorStore;
  }
  const store = useStore(samEditorStore);
  const {updateServerState} = store;

  useEffect(() => {
    // core signals plus optional signals, excluding stack signals
    const standardSignalList = _.difference(
      [...customer.signals, ...CoreScoringSignals],
      StackFitSignals
    );

    // core stack fit plus optional stack fit signals
    const stackFitSignalList = _.intersection(
      [...customer.signals, ...CoreStackFitSignals],
      StackFitSignals
    );

    const getSignalOptions = (signals: KeyplayScoringSignal[]) =>
      _.omit(
        fromEntriesMapped(signals, (signal) => [
          signal,
          ScoringSignalToLabel[signal],
        ]),
        // Omit signals that are redundant with other filter options
        ScoringOnlySignals
      );

    updateServerState(
      samDefinition,
      getSignalOptions(standardSignalList),
      getSignalOptions(stackFitSignalList)
    );
  }, [customer, samDefinition, updateServerState]);

  const publishMarket = usePublishMarket();
  const publishChanges = useMutation({
    mutationFn: () => {
      if (store.isEmpty()) {
        throw new Error('Cannot publish empty SAM');
      }

      if (!store.hasInitialized) {
        throw new Error('Cannot publish changes before initializing');
      }

      return publishMarket.mutateAsync({
        marketId,
        samDefinition: store.toServerState(),
      });
    },
  });

  return {
    ...store,
    publishChanges,
  };
};

import {useQueryClient} from '@tanstack/react-query';
import {useApiMutation} from './api';
import {SetOnboardingTemplate} from '../../shared/api/definitions';
import {MarketQueryKeys} from './markets';
import {MetadataQueryKey} from '../../context/MetadataContext';

export const useSetOnboardingTemplate = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/setOnboardingTemplate', SetOnboardingTemplate, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(MarketQueryKeys.all),
        queryClient.invalidateQueries([MetadataQueryKey]),
      ]);
    },
  });
};

import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from './api';
import {GetModelTest, PostModelTest} from '../../shared/api/definitions';

export const ScoringModelTestCacheKey = 'scoringModelTest';
export const ScoringModelTestKeys = {
  all: [ScoringModelTestCacheKey],
  market: (marketId: string) => [...ScoringModelTestKeys.all, marketId],
} as const;

export const useGetModelTest = createApiQuery(
  '/modelTest',
  GetModelTest,
  ({marketId}) => ScoringModelTestKeys.market(marketId),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
  }
);

export const usePublishModelTestFile = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/modelTest', PostModelTest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ScoringModelTestKeys.all);
    },
  });
};

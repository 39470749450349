import React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {useCanPublishMarketBuilder, useSaveMarketBuilder} from './tabState';
import {useMutation} from '@tanstack/react-query';
import {useNavigate} from 'react-router';
import {getRemaining} from '../../../shared/customer';
import {useCustomer, useIsFreemiumCustomer} from '../../../hooks/api/metadata';
import {Bold} from '../../text';
import {CreditsPurchaseModal} from '../../App/CreditUsage';

export const PublishButton = () => {
  const publishAndScoreModal = useDisclosure();
  const {disablePublish, errorMessage} = useCanPublishMarketBuilder();

  return (
    <>
      <Tooltip label={errorMessage} placement="bottom-start">
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          flexShrink="0"
          isDisabled={disablePublish}
          onClick={publishAndScoreModal.onOpen}
        >
          Exit & Score
        </Button>
      </Tooltip>
      <PublishAndScoreModal
        isOpen={publishAndScoreModal.isOpen}
        onClose={publishAndScoreModal.onClose}
      />
    </>
  );
};

const KeyplayPricingLink = () => (
  <Link
    color="kblue.300"
    display="inline-block"
    href="https://keyplay.io/pricing/"
    target="_blank"
  >
    See plans &amp; pricing
  </Link>
);

const PublishAndScoreModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const publishChanges = useSaveMarketBuilder({triggerRefresh: true});
  const navigate = useNavigate();
  const creditsModal = useDisclosure();

  const publishAndRescore = useMutation({
    mutationFn: async () => {
      return publishChanges.mutateAsync();
    },
    onSuccess: () => {
      onClose();
      navigate('/discover/recommended');
    },
  });

  const isFreemiumCustomer = useIsFreemiumCustomer();
  const customer = useCustomer();
  const remainingPublishes = getRemaining(customer, 'marketPublishes');
  const hasRemainingPublishes = remainingPublishes > 0;

  return (
    <>
      {/** Place outside the PublishAndScore modal so we can close it and open this one */}
      <CreditsPurchaseModal
        isOpen={creditsModal.isOpen}
        onClose={creditsModal.onClose}
      />
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="normal">
            Publish changes & score accounts?
          </ModalHeader>
          <ModalBody as={Flex} flexDirection="column" p="0">
            <Box px="6" pt="2" pb="4">
              <Text>What happens next:</Text>
              <List spacing="2" p="2">
                <ListItem>
                  ✅ Unsaved changes will be saved & published.
                </ListItem>
                <ListItem>
                  🧮 Scores will be re-calculated for all accounts.
                </ListItem>
                <ListItem>
                  ⏳ Scoring may take up to a few minutes to finish.
                </ListItem>
              </List>
            </Box>
            {isFreemiumCustomer &&
              (hasRemainingPublishes ? (
                <Alert status="warning" alignItems="start">
                  <AlertIcon />
                  <Box>
                    <Bold>{remainingPublishes}</Bold>{' '}
                    {remainingPublishes > 1 ? 'publishes' : 'publish'} left on
                    your free plan. <KeyplayPricingLink />
                  </Box>
                </Alert>
              ) : (
                <Alert status="error" alignItems="start">
                  <AlertIcon />
                  <Box>
                    You&apos;ve used all <Bold>10</Bold> publishes on your free
                    plan. <KeyplayPricingLink />
                  </Box>
                </Alert>
              ))}
          </ModalBody>
          <ModalFooter
            bgColor="kgray.100"
            borderTop="1px solid"
            borderColor="kgray.200"
            borderBottomRadius="md"
            gap="4"
            justifyContent="start"
          >
            {hasRemainingPublishes ? (
              <Button
                colorScheme="kbuttonblue"
                fontWeight="normal"
                isLoading={publishAndRescore.isLoading}
                onClick={() => publishAndRescore.mutate()}
              >
                Confirm
              </Button>
            ) : (
              <>
                <Button
                  colorScheme="kbuttonblue"
                  fontWeight="normal"
                  isDisabled={creditsModal.isOpen}
                  onClick={() => {
                    onClose();
                    creditsModal.onOpen();
                  }}
                >
                  Buy Credits
                </Button>
              </>
            )}
            <Button onClick={onClose} fontWeight="normal" variant="ghost">
              Back to editing
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  FieldDefinition,
  FieldDefinitionTypeLabels,
} from '../../shared/enrichment';
import {useCustomer} from '../../hooks/api/metadata';
import {pluralize} from '../../lib/helpers';
import {useLatestScoringRun} from '../../hooks/api/scoringRun';
import {OverflowMenuVertical} from '@carbon/icons-react';

export const CustomFields = () => {
  const customer = useCustomer();
  const fieldDefinitions = (customer.fieldDefinitions ?? []).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Text fontWeight="500">Custom Fields</Text>
        <Text textColor="kgray.300" fontSize="sm">
          {pluralize(fieldDefinitions.length, 'field', 'fields')}
        </Text>
      </Box>
      <CustomFieldsList fieldDefinitions={fieldDefinitions} />
    </Flex>
  );
};

const columnHelper = createColumnHelper<FieldDefinition>();

const CustomFieldMenu = ({fieldId}: {fieldId: string}) => {
  const {onCopy} = useClipboard(fieldId);
  const toast = useToast();

  const copyFieldId = () => {
    toast({
      title: 'Field ID copied to clipboard',
      duration: 1_000,
    });
    onCopy();
  };

  return (
    <Menu>
      <MenuButton>
        <OverflowMenuVertical />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={copyFieldId}>Copy Field ID</MenuItem>
      </MenuList>
    </Menu>
  );
};

const CustomFieldsList = ({
  fieldDefinitions,
}: {
  fieldDefinitions: FieldDefinition[];
}) => {
  const {data: scoringRun} = useLatestScoringRun({});

  const columns = [
    columnHelper.accessor('label', {
      header: 'Field Name',
      cell: (info) => {
        return <Box>{info.getValue()}</Box>;
      },
      size: 0,
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: (info) => {
        return (
          <Box textColor="kgray.300">
            {FieldDefinitionTypeLabels[info.getValue()]}
          </Box>
        );
      },
    }),
    columnHelper.accessor('id', {
      header: 'Accounts Enriched',
      cell: (info) => {
        const count =
          scoringRun?.enrichedFieldCounts[
            info.getValue().toString()
          ]?.toLocaleString();
        return (
          <Box textColor="kgray.300" textAlign="end" w="80%">
            {count ?? '-'}
          </Box>
        );
      },
    }),
    columnHelper.accessor('timestamp', {
      header: 'Created',
      size: 100,
      cell: (info) => {
        return (
          <Box textColor="kgray.300">
            {info.getValue().toLocaleDateString()}
          </Box>
        );
      },
    }),
    columnHelper.accessor((field) => field.id.toString(), {
      id: 'actions',
      header: '',
      size: 50,
      cell: (info) => {
        return <CustomFieldMenu fieldId={info.getValue()} />;
      },
    }),
  ];

  const table = useReactTable({
    columns,
    data: fieldDefinitions,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  if (!fieldDefinitions.length) {
    return (
      <Flex direction="column" align="center" p={8}>
        <Text textColor="kgray.300">No custom fields defined.</Text>
      </Flex>
    );
  }

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  _groupHover={{bg: 'kgray.100'}}
                  w={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

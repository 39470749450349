import React from 'react';
import {Helmet} from 'react-helmet';

export const Title = ({
  children,
  title,
}: React.PropsWithChildren<{title: string}>) => {
  return (
    <>
      <Helmet>
        <title>Keyplay - {title}</title>
      </Helmet>
      {children}
    </>
  );
};

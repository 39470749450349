import {z} from 'zod';
import {GenericTaskSchema} from '../task';
import {ObjectId} from 'bson';
import {SamDefinitionSchema, ScoringModelSchema} from '../market';
import {OnboardingTemplateRequestSchema} from '../onboarding';
import {
  AccountWithLogoSchema,
  CreditPriceResponseSchema,
  GetMarketResponseSchema,
} from './responses';
import {PlanSchema} from '../customer';
import {AccountQuerySchema} from './api';
import {ModelTestFileSchema, ModelTestSchema} from '../scoring';
import {ScoringRunSchema} from '../scoredAccounts';

const WithIdSchema = {
  _id: z.instanceof(ObjectId),
};

export type ApiOptions = {
  access: 'admin' | 'paid' | 'all';
};

// The ApiDefinition is the contract between the client the server for a given route.
export interface ApiDefinition<
  RequestData extends z.ZodType = z.ZodType<unknown>,
  ResponseData extends z.ZodType = z.ZodType<unknown>,
> {
  method: 'get' | 'post';
  options: ApiOptions;
  requestDataSchema: RequestData;
  responseDataSchema: ResponseData;
}

export const GetTasks = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    partialCustomerName: z.string().optional(),
  }),
  responseDataSchema: GenericTaskSchema.array(),
} satisfies ApiDefinition;

export const CancelTask = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    taskName: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const SetOnboardingTemplate = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: OnboardingTemplateRequestSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const BulkFindLookalikes = {
  method: 'post', // POST instead of GET due to the potential size of the request
  options: {access: 'all'},
  requestDataSchema: z.string().array(),
  responseDataSchema: AccountWithLogoSchema.array(),
} satisfies ApiDefinition;

export const GetCreditPrices = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({}),
  responseDataSchema: CreditPriceResponseSchema,
} satisfies ApiDefinition;

export const CreateCheckoutSession = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    priceId: z.string(),
  }),
  responseDataSchema: z.object({
    url: z.string(),
  }),
} satisfies ApiDefinition;

export const ClaimBonusCode = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    bonusCode: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetCustomers = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({}),
  responseDataSchema: z
    .object({
      _id: z.instanceof(ObjectId),
      name: z.string(),
      plan: PlanSchema,
    })
    .array(),
} satisfies ApiDefinition;

export const ClayExport = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    query: z.optional(AccountQuerySchema),
    webhookUrl: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetModelTest = {
  method: 'get',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: z.string(),
  }),
  responseDataSchema: ModelTestSchema.extend({
    fileName: z.string(),
  }).nullish(),
} satisfies ApiDefinition;

export const PostModelTest = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    file: ModelTestFileSchema,
    marketId: z.instanceof(ObjectId),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const PostModelTestGroup = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    domains: z.string().array(),
    marketId: z.instanceof(ObjectId),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

// TODO: move this const if we request/response types in their own file
export const MaxValidateDomainRows = 5_000;
export const ValidateDomains = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.array(
    z
      .object({
        domain: z.string(),
      })
      .catchall(z.unknown())
  ),
  responseDataSchema: z
    .object({
      domain: z.string(),
      finalDomain: z.string().optional(),
      status: z.string(),
    })
    .catchall(z.unknown())
    .array(),
} satisfies ApiDefinition;

export const GetMarket = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({
    marketId: z.string(),
  }),
  responseDataSchema: GetMarketResponseSchema,
} satisfies ApiDefinition;

export const PostMarket = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    marketId: z.instanceof(ObjectId),
    samDefinition: SamDefinitionSchema.optional(),
    scoringModel: ScoringModelSchema.partial().optional(),
    triggerRefresh: z.boolean().optional(),
  }),
  responseDataSchema: GetMarketResponseSchema,
} satisfies ApiDefinition;

export const GetLatestScoringRun = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({}),
  responseDataSchema: ScoringRunSchema.pick({
    enrichedFieldCounts: true,
    timestamp: true,
  })
    .extend(WithIdSchema)
    .optional(),
} satisfies ApiDefinition;
